<template>
  <div v-loading="assessLoading">
    <div class="profitRateResult" v-if="hasVal && level != 'ad'">
      <el-popover placement="top" title="" width="700" trigger="hover">
        <el-tabs v-model="activeTab" type="border-card">
          <el-tab-pane
            v-if="level == 'campaign'"
            label="商品经营分析"
            name="goods"
          >
            <product-report-list
              :productReport="productReport('product_report')"
            ></product-report-list>
          </el-tab-pane>
          <el-tab-pane
            name="product"
            :label="
              (level == 'campaign' ? '广告系列' : '广告组') + '层级经营分析'
            "
          >
            <product-report-list
              :productReport="productReport('adProductReport')"
            ></product-report-list>
          </el-tab-pane>
        </el-tabs>
        <div slot="reference">
          <img
            src="../../../../assets/icon/high.png"
            alt=""
            v-if="productReport(keyFlag).profitRateResult == 'HIGHT'"
          />
          <img
            src="../../../../assets/icon/middle.png"
            alt=""
            v-if="productReport(keyFlag).profitRateResult == 'MID'"
          />
          <img
            src="../../../../assets/icon/low.png"
            alt=""
            v-if="productReport(keyFlag).profitRateResult == 'LOW'"
          />
        </div>
      </el-popover>
    </div>
    <div v-else>--</div>
  </div>
</template>

<script>
import productReportList from "@/views/adManagement/components/table/productReportList";
import { ProductReport } from "./ProductReport.js";
export default {
  components: {
    productReportList,
  },
  props: {
    value: {
      type: Object,
    },
    level: {
      type: String,
      default: "campaign",
    },
    assessLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      activeTab: "goods",
    };
  },
  computed: {
    // activeTab(){
    //  return this.level=='campaign'?'goods':'product'
    // },
    productReport(key) {
      return function (key) {
        if (this.value.row.level == "campaign") {
          return this.value.row[key] || {};
        } else {
          if (this.value.row[key] && Object.keys(this.value.row[key]).length) {
            let purchase =
              this.value.row.object_actions ||
              this.value.row.purchase ||
              this.value.row.purchase_web;
            return new ProductReport(
              this.value.row[key],
              this.value.row.campaignPurchase,
              purchase,
              this.value.row.campaignCost,
              this.value.row.spend
            );
          } else {
            return {};
          }
        }
      };
    },
    hasReports() {
      return function (key) {
        // console.log(
        //   "this.productReport('product_report')",
        //   this.productReport("product_report")
        // );
        return Object.keys(this.productReport(key)).length ? true : false;
      };
    },
    keyFlag() {
      let key = this.level == "campaign" ? "product_report" : "adProductReport";
      return key;
    },
    hasVal() {
      return this.hasReports(this.keyFlag);
    },
  },
  mounted() {
    if (this.level != "campaign") {
      this.activeTab = "product";
    }
  },
};
</script>

<style lang="scss">
</style>